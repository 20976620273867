<template>
  <!-- FORM -->
  <form>
    <b-card>
      <!-- Title -->
      <b-card-title class="text-primary">
        Delivery Fee
      </b-card-title>

      <!-- Delivery Type -->
      <b-form-radio-group
        v-model="form.deliveryType"
        :options="deliveryTypes"
        class="mb-3"
        value-field="id"
        text-field="label"
      ></b-form-radio-group>

      <template v-if="form.deliveryType == 'paid'">
        <!-- Delivery Fee -->
        <b-form-group
          label="Delivery Fee"
          label-for="deliveryFee"
          :invalid-feedback="getErrorMessage(errors, 'deliveryFee')"
          :state="getErrorState(errors, 'deliveryFee')"
        >
          <b-form-input
            :state="getErrorState(errors, 'deliveryFee')"
            type="number"
            id="deliveryFee"
            v-model="form.deliveryFee"
            placeholder="Delivery"
            min="0"
            trim
          />
        </b-form-group>

        <!-- Free Above Amount -->
        <b-form-group
          label="Free Delivery on orders above"
          label-for="freeAboveAmount"
          :invalid-feedback="getErrorMessage(errors, 'freeAboveAmount')"
          :state="getErrorState(errors, 'freeAboveAmount')"
        >
          <b-form-input
            :state="getErrorState(errors, 'freeAboveAmount')"
            type="number"
            id="freeAboveAmount"
            v-model="form.freeAboveAmount"
            placeholder="Free Delivery on orders above"
            min="0"
            trim
          />
        </b-form-group>
      </template>

      <div class="text-center">
        <b-button @click.prevent="save" class="mr-2" variant="primary">
          Save
        </b-button>
        <b-button @click.prevent="cancel"> Cancel </b-button>
      </div>
    </b-card>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateDeliverySettings } from "../../../../apis/merchant";

const FREE = "free";
export default {
  created() {
    this.initDeliveryForm();
  },
  data() {
    return {
      form: {
        deliveryType: FREE,
        deliveryFee: 0,
        freeAboveAmount: 0,
      },
      errors: null,

      // Delivery Types
      deliveryTypes: [
        { id: FREE, label: "Free Delivery" },
        { id: "paid", label: "Paid Delivery" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    ...mapActions({
      initDeliverySettings: "merchant/deliverySettings/initSettings",
      setDeliverySettings: "merchant/deliverySettings/setSettings",
    }),
    initDeliveryForm() {
      this.initDeliverySettings(this.token).then((data) => {
        this.form = { ...data };
        if (!this.form.deliveryType) {
          this.form.deliveryType = FREE;
        }
      });
    },
    save() {
      updateDeliverySettings(this.form, null, this.token)
        .then((data) => {
          // update settings
          this.setDeliverySettings(data);
          this.form = { ...data };
          // go back
          this.cancel();
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
